var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscriptions-container"},[(!_vm.showMenuNavigator)?_c('category-block',{staticClass:"category-block category-block-1",attrs:{"target":_vm.category,"position":"position1"}}):_vm._e(),_c('CategoryTitle',{attrs:{"category":_vm.category}}),(
      !_vm.loading && _vm.buyedUserSubscriptions && _vm.buyedUserSubscriptions.length > 0
    )?_c('v-list',{staticClass:"subscription-list buyed-subscription",attrs:{"flat":""}},_vm._l((_vm.buyedUserSubscriptions),function(subscription){return _c('SubscriptionItem',{key:subscription.subscriptionPlanId,attrs:{"subscription":subscription,"userSubscriptions":_vm.userSubscriptions},on:{"addSubscriptionPlan":_vm.doAddSubscriptionPlan,"reload":_vm.reload}})}),1):_vm._e(),_c('h4',{staticClass:"text-body-1 mt-6 mb-5 font-weight-bold primary--text text-center"},[_vm._v(" "+_vm._s(_vm.$t("subscriptionsModule.avaibilityDisclaimer"))+" ")]),(Object.keys(_vm.$t('subscriptions')).length > 0)?_c('div',{staticClass:"stepper-container d-flex flex-column flex-sm-row py-3 mb-3"},_vm._l((Object.keys(_vm.$t('subscriptions')).length),function(step,index){return _c('div',{key:index,staticClass:"d-flex flex-column text-center justify-center align-center h-100 px-2 pt-4",class:{
        'step-separator': index < Object.keys(_vm.$t('subscriptions')).length - 1
      }},[_c('div',{staticClass:"primary white--text dot pa-6 rounded-circle d-flex justify-center align-center"},[_c('span',{staticClass:"number font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.$t('subscriptions.' + Number(index + 1) + '.number'))}})]),_c('div',[_c('strong',{domProps:{"innerHTML":_vm._s(_vm.$t('subscriptions.' + Number(index + 1) + '.title'))}})]),_c('div',[_c('p',{staticClass:"stepper-description text-body-2",domProps:{"innerHTML":_vm._s(_vm.$t('subscriptions.' + Number(index + 1) + '.description'))}})])])}),0):_vm._e(),(
      !_vm.loading &&
        _vm.buyableUserSubscriptions &&
        _vm.buyableUserSubscriptions.length > 0
    )?_c('v-list',{staticClass:"subscription-list buyable-subscription",attrs:{"flat":""}},_vm._l((_vm.buyableUserSubscriptions),function(subscription){return _c('SubscriptionItem',{key:subscription.subscriptionPlanId,attrs:{"subscription":subscription,"userSubscriptions":_vm.userSubscriptions},on:{"addSubscriptionPlan":_vm.doAddSubscriptionPlan,"reload":_vm.reload}})}),1):_vm._e(),_c('v-spacer',{staticClass:"spacer-scroll"}),_c('category-block',{staticClass:"category-block category-block-2",attrs:{"target":_vm.category,"position":"position2"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }