<template>
  <div class="subscriptions-container">
    <category-block
      v-if="!showMenuNavigator"
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <CategoryTitle :category="category" />

    <v-list
      flat
      class="subscription-list buyed-subscription"
      v-if="
        !loading && buyedUserSubscriptions && buyedUserSubscriptions.length > 0
      "
    >
      <SubscriptionItem
        v-for="subscription in buyedUserSubscriptions"
        :key="subscription.subscriptionPlanId"
        :subscription="subscription"
        :userSubscriptions="userSubscriptions"
        @addSubscriptionPlan="doAddSubscriptionPlan"
        @reload="reload"
      />
    </v-list>

    <h4
      class="text-body-1 mt-6 mb-5 font-weight-bold primary--text text-center"
    >
      {{ $t("subscriptionsModule.avaibilityDisclaimer") }}
    </h4>

    <div
      class="stepper-container d-flex flex-column flex-sm-row py-3 mb-3"
      v-if="Object.keys($t('subscriptions')).length > 0"
    >
      <div
        class="d-flex flex-column text-center justify-center align-center h-100 px-2 pt-4"
        v-for="(step, index) in Object.keys($t('subscriptions')).length"
        :key="index"
        :class="{
          'step-separator': index < Object.keys($t('subscriptions')).length - 1
        }"
      >
        <div
          class="primary white--text dot pa-6 rounded-circle d-flex justify-center align-center"
        >
          <span
            class="number font-weight-bold"
            v-html="$t('subscriptions.' + Number(index + 1) + '.number')"
          ></span>
        </div>
        <div>
          <strong
            v-html="$t('subscriptions.' + Number(index + 1) + '.title')"
          ></strong>
        </div>
        <div>
          <p
            class="stepper-description text-body-2"
            v-html="$t('subscriptions.' + Number(index + 1) + '.description')"
          ></p>
        </div>
      </div>
    </div>

    <v-list
      flat
      class="subscription-list buyable-subscription"
      v-if="
        !loading &&
          buyableUserSubscriptions &&
          buyableUserSubscriptions.length > 0
      "
    >
      <SubscriptionItem
        v-for="subscription in buyableUserSubscriptions"
        :key="subscription.subscriptionPlanId"
        :subscription="subscription"
        :userSubscriptions="userSubscriptions"
        @addSubscriptionPlan="doAddSubscriptionPlan"
        @reload="reload"
      />
    </v-list>

    <v-spacer class="spacer-scroll"></v-spacer>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style scoped lang="scss">
.subscriptions-container {
  .stepper-container {
    .step-separator {
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        border-right: 1px solid rgba(0, 0, 0, 0.25);
        border-bottom: none;
      }
    }
  }
  .dot {
    .number {
      position: absolute;
      font-size: 20px;
    }
  }

  .cart {
    position: relative;
    padding-bottom: 56px;
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import SubscriptionItem from "./SubscriptionItem.vue";

import productMixins from "~/mixins/product";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";
// import ProductPromo from "@/components/product/ProductPromo.vue";

import { mapActions, mapState } from "vuex";
// import { forEachCartItem } from "~/service/ebsn";

import subscriptionPlanService from "~/service/subscriptionPlanService";

export default {
  name: "Subscriptions",
  mixins: [login, productMixins, cartInfo, categoryMixins],
  props: { showMenuNavigator: { type: Boolean, default: false } },
  components: {
    CategoryTitle,
    SubscriptionItem
  },
  data() {
    return {
      loading: true,
      userSubscriptions: []
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    buyedUserSubscriptions() {
      const result = this.userSubscriptions.filter(userSubscription => {
        if (
          userSubscription.active == 1 &&
          userSubscription.subscriptionPlanUsers &&
          userSubscription.subscriptionPlanUsers.length > 0
        ) {
          let hasSubscriptionPlanUserActive = userSubscription.subscriptionPlanUsers.find(
            subscriptionPlanUser =>
              subscriptionPlanUser.subscriptionPlanUserStatus
                .subscriptionPlanUserStatusId == 3 ||
              (subscriptionPlanUser.subscriptionPlanUserStatus
                .subscriptionPlanUserStatusId == 8 &&
                this.$dayjs(subscriptionPlanUser.endDate).isAfter(
                  this.$dayjs()
                ))
          );
          if (hasSubscriptionPlanUserActive) {
            return true;
          }
        }
        return false;
      });
      return result;
    },
    buyableUserSubscriptions() {
      let result = [];
      if (
        this.userSubscriptions.length > 0 &&
        this.buyedUserSubscriptions.length >= 0
      )
        result = this.userSubscriptions.filter(userSubscription => {
          return !this.buyedUserSubscriptions.some(
            buyedUserSubscription =>
              buyedUserSubscription.subscriptionPlanId ===
              userSubscription.subscriptionPlanId
          );
        });
      return result;
    }
  },
  methods: {
    ...mapActions({
      loadCart: "cart/loadCart",
      addSubscriptionPlan: "cart/addSubscriptionPlan"
    }),
    // async getSubscriptionsList() {
    //   let subscriptions = await subscriptionPlanService.subscriptionPlanList();
    //   this.subscriptions = subscriptions.subscriptionPlans;
    // },
    async getUserSubscriptionsList() {
      let userSubscriptions = await subscriptionPlanService.userSubscriptionPlanList();
      this.userSubscriptions = userSubscriptions.subscriptionPlans
        ? userSubscriptions.subscriptionPlans
        : [];
    },
    async doAddSubscriptionPlan(subscriptionPlan) {
      let params = {
        subscriptionPlanId: subscriptionPlan
      };
      // this.addSubscriptionPlan(params);
      let result = await subscriptionPlanService.addSubscriptionPlan(params);
      if (
        (result.response && result.response.status == 0) ||
        result.status == 0
      ) {
        this.loadCart();
        // this.reload();
        this.userSubscriptions = result.data.subscriptionPlans;
      }
    },
    async reload() {
      // await this.getSubscriptionsList();
      await this.getUserSubscriptionsList();
    }
  },
  async mounted() {
    this.loading = true;
    await this.reload();
    this.loading = false;
  },
  created() {
    let vm = this;
    global.EventBus.$on("deleteSubscriptionPlan", () => {
      vm.reload();
    });
  },
  beforeDestroy() {
    global.EventBus.$off("deleteSubscriptionPlan");
  }
};
</script>
