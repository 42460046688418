<template>
  <v-list-item class="subscription-item">
    <v-card
      class="subscription-card d-flex flex-column flex-md-row justify-space-between mb-5 w-100"
    >
      <div
        class="subscription-card-content d-flex flex-column flex-md-row align-center pa-3"
      >
        <v-avatar tile size="150">
          <v-img
            v-if="subscription.product && subscription.product.mediaURL"
            :src="subscription.product.mediaURL"
            :alt="'Immagine prodotto'"
          />
        </v-avatar>
        <div class="d-flex flex-column">
          <v-card-title>
            {{ subscription.name }}
          </v-card-title>
          <v-card-subtitle class="mt-0">
            {{ subscription.descr }}
          </v-card-subtitle>
          <!-- isActive:{{ isActive }}<br />
          isExpired: {{ isExpired }}<br />
          isExpiring: {{ isExpiring }}<br />
          subscriptionAdded: {{ subscriptionAdded }}<br />
          isSubscriptionOrdered: {{ isSubscriptionOrdered }}<br /> -->
        </div>
      </div>
      <div
        class="card-content pa-2 pa-md-0 d-flex flex-column align-center justify-center"
      >
        <!-- <div class="is-expired" v-if="isActive && isExpired">
          <span class="expired-text">
            {{ $t("subscriptionsModule.expiredSubscription") }}
          </span>
          <span v-if="renewalDate" class="expired-date">
            {{ $t("subscriptionsModule.expired") }}
            {{ $dayjs(renewalDate).format("D MMMM YYYY") }}
          </span>
        </div> -->
        <v-card-text
          v-if="!isActive && subscription.product"
          class="product-price-text"
        >
          <ProductPrice
            :product="subscription.product"
            :showDescrWeight="false"
            :showOldPrice="false"
          ></ProductPrice>
        </v-card-text>
        <v-card-subtitle
          v-if="isActive && isExpired"
          class="d-flex justify-center flex-column align-center"
        >
          <div
            class="d-flex justify-center subscription-status font-body-1 font-weight-bold expired"
          >
            <p class="mb-2">
              <b>{{ $t("subscriptionsModule.expiredSubscription") }}</b>
            </p>
          </div>
          <div
            class="d-flex justify-center subscription-expire font-weight-bold text-caption"
          >
            {{ $t("subscriptionsModule.expired") }}
            {{ $dayjs(renewalDate).format("D MMMM YYYY") }}
          </div>
          <i18n-n
            :value="subscription.product.priceDisplay"
            format="currency"
            tag="span"
            class="renew-subscription-price formatted-price font-weight-bold py-1 px-3 mt-1"
          >
            <template v-slot:currency="slotProps">
              <span class="currency">{{ slotProps.currency }}</span>
            </template>
          </i18n-n>
        </v-card-subtitle>
        <v-card-subtitle
          class="d-flex flex-column"
          :class="{
            'align-start': isActive && $vuetify.breakpoint.xsOnly
          }"
          v-else-if="isActive && !isExpiring && !isExpired"
        >
          <div
            class="d-flex justify-center primary--text font-body-1 font-weight-bold"
          >
            <p>
              {{ $t("subscriptionsModule.activeSusbscription") }}
            </p>
          </div>
          <div
            class="d-flex justify-center subscription-expire font-weight-bold text-caption"
          >
            Scadenza
            {{ $dayjs(subscriptionPlanUser.endDate).format("D MMMM YYYY") }}
          </div>
          <v-btn
            medium
            depressed
            color="primary"
            class="subs-btn mt-3"
            @click.stop.prevent="addSubscriptionPlan()"
            :disabled="subscriptionAdded || (isActive && !isExpiring)"
          >
            <span>
              {{ $t("subscriptionsModule.renewSubscription") }}
            </span>
          </v-btn>
          <div
            class="d-flex justify-center renewable-date primary--text font-weight-bold text-caption mt-1"
            v-if="subscription.renewalDaysBefore"
          >
            {{
              $t("subscriptionsModule.renewableDate", {
                renewalDate: renewableDate
              })
            }}
          </div>
        </v-card-subtitle>

        <v-card-actions
          class="v-card-actions d-flex flex-column"
          v-if="isActive && isExpiring"
        >
          <!-- abbonamento in scadenza da rinnovare -->
          <div
            class="d-flex justify-center subscription-status font-body-1 font-weight-bold expiring"
          >
            {{ $t("subscriptionsModule.expiringSubscription") }}
          </div>
          <div
            class="d-flex justify-center subscription-expire font-weight-bold text-caption"
          >
            {{ $t("subscriptionsModule.expiring") }}
            {{ $dayjs(renewalDate).format("D MMMM YYYY") }}
          </div>
          <i18n-n
            :value="subscription.product.priceDisplay"
            format="currency"
            tag="span"
            class="renew-subscription-price formatted-price font-weight-bold py-1 px-3 my-2"
          >
            <template v-slot:currency="slotProps">
              <span class="currency">{{ slotProps.currency }}</span>
            </template>
          </i18n-n>
          <v-btn
            medium
            block
            depressed
            color="primary"
            class="subs-btn expired-btn"
            @click.stop.prevent="addSubscriptionPlan()"
            :disabled="subscriptionAdded"
          >
            <span>
              {{ $t("subscriptionsModule.renewSubscription") }}
            </span>
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <div
            v-if="isSubscriptionOrdered"
            class="d-flex justify-center subscription-status font-weight-bold mt-3"
          >
            <p>
              {{ $t("subscriptionsModule.orderedSubscription") }}
            </p>
          </div>
          <v-btn
            v-else-if="subscriptionAdded"
            @click.stop.prevent="removeFromCart()"
            medium
            depressed
            outlined
            color="primary"
            class="subs-btn"
          >
            <v-icon class="mr-2">$cart</v-icon>
            <span>
              {{ $t("subscriptionsModule.addedToCart") }}
            </span>
          </v-btn>
          <v-btn
            v-else-if="!isActive || (isActive && isExpired)"
            medium
            depressed
            color="primary"
            class="subs-btn"
            @click.stop.prevent="addSubscriptionPlan()"
            :disabled="isSubscriptionOrdered"
          >
            <v-icon color="white" class="mr-2">$cart</v-icon>
            <span>
              {{ $t("subscriptionsModule.addToCart") }}
            </span>
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-list-item>
</template>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";
import productMixin from "~/mixins/product";
import { mapState } from "vuex";

export default {
  name: "SubscriptionItem",
  mixins: [productMixin],
  components: {
    ProductPrice
  },
  props: {
    subscription: { type: Object, required: true }
    // userSubscriptions: { type: Array, default: () => [] }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    product() {
      return this.subscription.product;
    },
    subscriptionPlanUser() {
      let result;
      if (
        this.subscription.subscriptionPlanUsers &&
        this.subscription.subscriptionPlanUsers.length > 0
      ) {
        this.subscription.subscriptionPlanUsers.forEach(ss => {
          result = ss;
        });
      }
      return result;
    },
    renewalDate() {
      let result;

      if (this.isExpiring) {
        result = this.$dayjs(this.subscriptionPlanUser.endDate);
      } else if (this.isExpired && this.subscriptionPlanUser.active === 1) {
        result = this.$dayjs(this.subscriptionPlanUser.endDate).format(
          "YYYY-MM-DD"
        );
      }

      return result;
    },
    renewableDate() {
      if (
        this.subscriptionPlanUser?.endDate &&
        this.subscription.renewalDaysBefore
      ) {
        return this.$dayjs(this.subscriptionPlanUser.endDate)
          .subtract(this.subscription.renewalDaysBefore, "day")
          .format("D MMMM YYYY");
      }
      return this.$dayjs();
    },
    isExpiring() {
      let result = false;

      if (
        this.subscriptionPlanUser &&
        this.subscriptionPlanUser.active === 1 &&
        this.subscriptionPlanUser.endDate &&
        this.$dayjs(this.subscriptionPlanUser.endDate) &&
        this.$dayjs(this.subscriptionPlanUser.endDate).isAfter(this.$dayjs()) &&
        this.subscription.renewalDaysBefore >=
          this.$dayjs(this.subscriptionPlanUser.endDate).diff(
            this.$dayjs().format("YYYY-MM-DD"),
            "day"
          )
      ) {
        result = true;
      }

      return result;
    },
    isExpired() {
      let result = false;

      if (
        this.subscriptionPlanUser &&
        (!this.subscriptionPlanUser.active ||
          (this.subscriptionPlanUser.active &&
            this.subscriptionPlanUser.active === 0) ||
          (this.subscriptionPlanUser.active &&
            this.subscriptionPlanUser.active === 1 &&
            this.$dayjs(this.subscriptionPlanUser.endDate).format(
              "YYYY-MM-DD"
            ) < this.$dayjs().format("YYYY-MM-DD")))
      ) {
        result = true;
      }

      return result;
    },
    isActive() {
      if (
        this.subscription.active == 1 &&
        this.subscription.subscriptionPlanUsers
      ) {
        if (
          this.subscriptionPlanUser.active == 1 &&
          this.subscriptionPlanUser.subscriptionPlanUserStatus
            .subscriptionPlanUserStatusId == 8
        ) {
          return true;
        }
      }
      return false;
    },
    isSubscriptionOrdered() {
      if (
        this.subscription.active == 1 &&
        this.subscription.subscriptionPlanUsers
      ) {
        if (
          this.subscriptionPlanUser.active == 1 &&
          this.subscriptionPlanUser.subscriptionPlanUserStatus
            .subscriptionPlanUserStatusId == 3
        ) {
          return true;
        }
      }

      return false;
    },
    subscriptionAdded() {
      let result = false;
      for (let i = 0; this.cart.cartItems.length > i; i++) {
        if (
          this.cart.cartItems[i].cartItemInfo.SUBSCRIPTION_PLAN_ID ==
          this.subscription.subscriptionPlanId
        ) {
          result = true;
          break;
        }
      }
      return result;
    },
    cartItem() {
      let result = false;
      for (let i = 0; this.cart.cartItems.length > i; i++) {
        if (
          this.cart.cartItems[i].cartItemInfo.SUBSCRIPTION_PLAN_ID ==
          this.subscription.subscriptionPlanId
        ) {
          result = this.cart.cartItems[i];
          break;
        }
      }
      return result;
    }
    // isBuyable() {},
    // isRenewable() {},
    // isAlreadyBuyed() {}
  },
  methods: {
    addSubscriptionPlan() {
      this.$emit("addSubscriptionPlan", this.subscription.subscriptionPlanId);
    },
    async removeFromCart() {
      await this.removeItem(this.cartItem);
      this.$emit("reload");
    }
  }
};
</script>
<style lang="scss">
.subscription-item {
  gap: 8px;
  width: 100%;
  .card-content {
    position: relative;
    .product-price-text {
      box-sizing: content-box;
    }
    .renew-subscription-price {
      border: 2px solid var(--v-primary-base);
      border-radius: $border-radius-root;
    }
    min-width: 350px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      min-width: auto;
    }
  }
  .subscription-status {
    // color: #5bbe0d;
    color: var(--v-primary-base);
  }
  .expiring {
    color: $secondary !important;
  }
  .expired {
    color: #ff0000 !important;
  }
  .is-expired {
    position: absolute;
    top: 4px;
    right: 4px;
    border: 1px solid var(--v-secondary-base);
    font-size: 0.7rem;
    padding: 1px 8px;
    border-radius: $border-radius-root;
    .expired-text {
      font-weight: 900;
      color: var(--v-secondary-base);
    }
    .expired-date {
      font-weight: 700;
      color: black;
    }
  }
}
</style>
